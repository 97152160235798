import { template as template_ca28a67b97134fa49d15c20245384ce3 } from "@ember/template-compiler";
const FKLabel = template_ca28a67b97134fa49d15c20245384ce3(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
