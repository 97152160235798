import { template as template_af35394bf6c645eb9b76a01cf9cf7a93 } from "@ember/template-compiler";
const FKText = template_af35394bf6c645eb9b76a01cf9cf7a93(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
