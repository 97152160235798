import { template as template_02aa8c2c8d3942b8bd128a6b59eb2f07 } from "@ember/template-compiler";
const WelcomeHeader = template_02aa8c2c8d3942b8bd128a6b59eb2f07(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
