import { template as template_36f4d8ca65af4e4a866ac75230683a78 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_36f4d8ca65af4e4a866ac75230683a78(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
