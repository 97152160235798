import { template as template_d58680c9723049939ba7c2f5b6f0f9db } from "@ember/template-compiler";
const SidebarSectionMessage = template_d58680c9723049939ba7c2f5b6f0f9db(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
