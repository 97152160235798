import { template as template_795fc96df3d44f458736645db4ef0a7a } from "@ember/template-compiler";
const FKControlMenuContainer = template_795fc96df3d44f458736645db4ef0a7a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
